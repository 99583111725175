<template>
  <div class="citizen-charter-wrapper">
    <div class="mb-48 component__header">
      <h1>कागजात संग्रह</h1>
    </div>
    <div class="container">
      <div
        class="main-container citizen-charter-detail-wrapper"
        v-if="Object.keys(details).length"
      >
        <div class="flex flex-pdf">
          <span
            class="mb-24 back-button"
            @click="$router.push(`/document-library/`)"
          >
            कागजात संग्रह फिर्ता जानुहोस्
          </span>
          <button
            @click="$store.dispatch('windowPrint')"
            class="btn download-pdf"
          >
            PDF डाउनलोड गर्नुहोस्
          </button>
        </div>
        <div class="mb-24">
          <div class="flex-content">
            <h2>{{ details.document_title }}</h2>
            <div class="dropdown">
              <div class="pill-download dropbtn">
                कागजात डाउनलाेड गर्नुहाेस्
              </div>
              <div class="dropdown-content">
                <a
                  :href="files"
                  v-for="(files, i) in details.documents"
                  :key="i"
                  target="_blank"
                  rel="noreferrer"
                  :title="getFilename(files)"
                  >{{ getFilename(files) }}
                  <span class="download">डाउनलाेड</span>
                </a>
              </div>
            </div>
          </div>
          <span class="pill-data">{{ getDateTime(details.created_at) }}</span>
          <span class="pill-data" v-if="details.description">{{
            details.description
          }}</span>
        </div>
        <div class="mb-24 card">
          <dl class="flex">
            <dt>कागजातको प्रकार</dt>
            <dd>{{ details.document_type.ne }}</dd>
            <dt>कागजातकाे स्राेत</dt>
            <dd>{{ details.document_source.ne }}</dd>
            <dt>वर्ग (उपवर्ग)</dt>
            <dd>{{ listCategories(details.categories) }}</dd>
            <dt>स्रोतहरू(url)</dt>
            <dd>
              <a
                :href="url"
                v-for="(url, i) in details.source_url"
                :key="i"
                target="_blank"
                rel="noreferrer"
                :title="url"
                >{{ url }}</a
              >
            </dd>
            <dt>प्रकाशित मिति</dt>
            <dd>{{ englishToNepaliNumber(details.published_year) }}</dd>
          </dl>
        </div>
        <h2>सम्बन्धित कागजातहरू</h2>
        <div
          class="mb-24 card card-document"
          v-for="(doc, i) in details.related_documents.data"
          :key="i"
        >
          <dl class="flex">
            <dt class="created-document-date">
              {{ getDateTime(doc.created_at) }}
            </dt>
            <dd>
              <span class="file-type">{{
                getExtension(getFilename(doc.documents[0]))
              }}</span>
            </dd>
            <dt>{{ doc.document_title }}</dt>
            <dd>
              <span
                class="flex more"
                @click="$router.push(`/document-library/${doc.id}`)"
                >थप जानकारी <i class="arrow-right"></i
              ></span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <PdfDownload ref="pdf">
    <template v-slot:content>
      <div>
        <h1 class="mb-16">कागजात संग्रह</h1>
        <h2 class="mb-16">{{ details.document_title }}</h2>
        <div class="mb-24">
          <div v-if="Object.keys(details).length">
            <table>
              <tr>
                <td>कागजातको प्रकार</td>
                <td>{{ details.document_type.ne }}</td>
              </tr>
              <tr>
                <td>कागजातकाे स्राेत</td>
                <td>{{ details.document_source.ne }}</td>
              </tr>
              <tr>
                <td>वर्ग (उपवर्ग)</td>
                <td>{{ listCategories(details.categories) }}</td>
              </tr>
              <tr>
                <td>स्रोतहरू(url)</td>
                <td>
                  <a
                    :href="url"
                    v-for="(url, i) in details.source_url"
                    :key="i"
                    target="_blank"
                    rel="noreferrer"
                    :title="url"
                    >{{ url }}</a
                  >
                </td>
              </tr>
              <tr>
                <td>प्रकाशित मिति</td>
                <td>{{ englishToNepaliNumber(details.published_year) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </template>
  </PdfDownload>
</template>

<script>
import { englishToNepaliNumber } from "nepali-number";
import { listCategories } from "./../../helper/helper";
import NepaliDate from "nepali-date-converter";
import DocumentationHubRepository from "./../../repositories/DocumentationHubRepository";
import PdfDownload from "./../../components/shared/PdfDownload.vue";
export default {
  data() {
    return {
      details: {},
      englishToNepaliNumber,
      listCategories,
    };
  },
  components: {
    PdfDownload,
  },
  methods: {
    async getDetails() {
      try {
        let { data } =
          await DocumentationHubRepository.getDocumentationHubDetail(
            this.$route.params.id
          );
        this.details = data.data;
      } catch (error) {
        console.error(error);
      }
      this.$store.dispatch("setLoading", false);
    },
    getDateTime(date) {
      let format = "";
      if (date) {
        let unixtime = Date.parse(date);
        let nep = new NepaliDate(unixtime);
        format = nep.format("MMMM DD, YYYY", "np");
        format += " मा अपलाेड गरिएकाे";
      }
      return format;
    },
    getFilename(url) {
      let filename = "";
      if (url) {
        const segments = new URL(url).pathname.split("/");
        filename = segments.pop() || segments.pop(); // Handle potential trailing slash
      }
      return filename;
    },
    getExtension(filename) {
      return filename.split(".").pop().toUpperCase();
    },
  },
  watch: {
    "$route.params.id": {
      handler: "getDetails",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  &-content {
    background-color: $primary-light;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0;
    margin-top: 10px;
    position: absolute;
    width: 250px;
    z-index: 1;
    a {
      color: $neutrals-black;
      font-size: 14px;
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
    }
  }
}

.dropdown-content a:hover {
  background-color: darken($primary-light, 5%);
  .download {
    background: -webkit-linear-gradient(#f97794, #623aa2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:before {
      background-position: -34px -160px;
    }
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.flex-content {
  display: flex;
  justify-content: space-between;
}

.pill-download {
  background: $primary;
  border-radius: 55px;
  color: $neutrals-white;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: auto;
  padding: 10px 30px;
}

.pill-data {
  padding: 4px 13px;
  margin-right: 16px;
  border: 1px solid $neutrals-black;
  border-radius: 24px;
  font-size: 14px;
  font-family: "Mukta-SemiBold";
  margin-right: 16px;
  padding: 4px 13px;
}

.created-document-date {
  font-size: 14px;
  font-family: "Mukta-Bold";
}

.file-type {
  background: $primary-light;
  border-radius: 24px;
  display: block;
  font-size: 12px;
  font-family: "Mukta-SemiBold";
  line-height: 24px;
  text-align: center;
  width: 58px;
}

.card-document {
  dt {
    width: 80%;
  }
  dd {
    width: 20%;
    > * {
      float: right;
    }
  }
}
</style>
